import { EmarsysServiceWorker } from './lib/emarsys-service-worker'
import { MEWebPushDb } from './lib/me-web-push-db'
import { IndexDb } from './lib/index-db'

const webPushDb = MEWebPushDb.create(IndexDb.create())
const worker = EmarsysServiceWorker.create(webPushDb)

self.addEventListener('push', event => { worker.onPush(event as PushEvent) })
self.addEventListener('notificationclick', event => worker.onNotificationClick(event as NotificationEvent))
self.addEventListener('install', event => worker.onInstall(event as ExtendableEvent))
self.addEventListener('pushsubscriptionchange',
  event => worker.onSubscriptionChange(event as PushSubscriptionChangeEvent)
)
