import logging from './logging'
import {
  pushActionsProperty,
  pushIconProperty,
  pushImageProperty,
  pushLinkProperty,
  pushTitleProperty
} from './constants'
import { isValidPayload } from './utils'
import { MEWebPushDb } from './me-web-push-db'
import {
  MEDeviceEventService,
  MEEvent,
  MEEventAttributes,
  MEEventsRequestData,
  PostEventsResult,
  WebPushTreatments
} from './me-device-event-service'
import { MEClientService } from './me-client-service'
import { MEV3ApiRequest } from './me-v3-api-request'

declare const self: ServiceWorkerGlobalScope

type ActionButton = {
  id: string
  title: string
  url: string
}

type DbFallbackFn<T> = () => Promise<T | undefined>
type NotificationData<T> = { [key: string]: T }

const FailureResult: PostEventsResult = { success: false }

/**
 * EmarsysServiceWorker class is responsible for receiving push notifications and shows the notification.
 */
export class EmarsysServiceWorker {
  private webPushDb: MEWebPushDb
  private meDeviceEventService?: MEDeviceEventService
  private meClientService?: MEClientService

  constructor (
    webPushDb: MEWebPushDb
  ) {
    this.webPushDb = webPushDb
  }

  onInstall (event: ExtendableEvent): void {
    event.waitUntil(this.handleInstall())
  }

  onPush (event: PushEvent): void {
    event.waitUntil(this._onPush(event))
  }

  onNotificationClick (event: NotificationEvent): void {
    event.waitUntil(this._onNotificationClick(event))
  }

  onSubscriptionChange (event: PushSubscriptionChangeEvent): void {
    event.waitUntil(this._onSubscriptionChange())
  }

  private async _onPush (event: PushEvent): Promise<any> {
    await this.setupLogging()

    if (!('showNotification' in self.registration)) {
      logging.Logger.warn('Showing of notifications is not enabled')
      return
    }
    // eslint-disable-next-line
    const payloadJson = event.data && event.data.json() ? event.data.json() : {}

    if (!isValidPayload(payloadJson)) {
      logging.Logger.warn('Invalid payload', payloadJson)
      return
    }
    // @ts-expect-error
    const notificationSettings = payloadJson.messageData.notificationSettings
    return Promise.all([
      this.getNotificationOption(
      // @ts-expect-error
        payloadJson, pushTitleProperty, () => this.webPushDb.getDefaultNotificationTitle('')
      ),
      this.getNotificationOption(
        notificationSettings, pushIconProperty, () => this.webPushDb.getDefaultNotificationIcon(undefined)
      ),
      this.getNotificationOption(
        notificationSettings, pushImageProperty, () => Promise.resolve(undefined)
      ),
      this.getNotificationOption<ActionButton[]>(
        notificationSettings, pushActionsProperty, () => Promise.resolve(undefined)
      )
    ]).then(
      ([notificationTitle, notificationIcon, notificationImage, notificationActions]:
      [string | undefined, string | undefined, string | undefined, ActionButton[] | undefined]) => {
        return this.showNotification(
          // @ts-expect-error
          payloadJson.message,
          payloadJson,
          notificationTitle as string,
          notificationIcon,
          notificationImage,
          notificationActions ? this.createActionsFromActionButtons(notificationActions) : notificationActions
        )
      })
  }

  private async _onNotificationClick (event: NotificationEvent): Promise<any> {
    await this.setupLogging()

    logging.Logger.debug(`Notification clicked with Action: ${event.action}`)

    event.notification.close()
    const payload = event.notification.data

    if (!payload.messageData.notificationSettings) {
      return
    }

    let url = payload.messageData.notificationSettings[pushLinkProperty]

    if (payload.messageData.notificationSettings[pushActionsProperty]) {
      const buttonClicked = payload.messageData.notificationSettings[pushActionsProperty]
        .find((actionButton: ActionButton) => actionButton.id === event.action)

      if (buttonClicked) {
        url = buttonClicked.url
      }
    }
    // eslint-disable-next-line
    const commands: Promise<unknown>[] = []

    if (url) {
      // eslint-disable-next-line
      logging.Logger.debug(`Opening url: ${url}`)
      commands.push(self.clients.openWindow(url))
    }
    commands.push(this.reportOpen(payload))

    return Promise.all(commands)
  }

  private async _onSubscriptionChange (): Promise<any> {
    try {
      await this.setupLogging()
      logging.Logger.debug('Subscription changed')

      const applicationServerKey = await this.getApplicationServerKey()
      logging.Logger.debug('Got applicationServerKey', JSON.stringify(applicationServerKey))

      if (!applicationServerKey) {
        logging.Logger.debug('Exiting registerNewSubscription')
        return
      }

      logging.Logger.debug('Subscribing for new key')
      const subscription: PushSubscription = await self.registration.pushManager
        .subscribe({ userVisibleOnly: true, applicationServerKey })
      logging.Logger.debug('Registering new subscription', subscription)
      // eslint-disable-next-line
      return this.registerNewSubscription(subscription, false)
    } catch (err) {
      logging.Logger.error('onSubscriptionChange: registerSubscription', err)
    }
  }

  private async registerNewSubscription (
    subscription: PushSubscription,
    isRetry: boolean = false
  ): Promise<void> {
    const meClientSvc = await this.getMeClientService()
    if (!meClientSvc) {
      logging.Logger.error('Unable to get the ME client service!')
      return
    }
    let success = await meClientSvc.registerPushToken(JSON.stringify(subscription))
    if (success) {
      logging.Logger.debug('Success register push token with backend')
      return
    }
    if (isRetry) {
      logging.Logger.error('Unable to register expired subscription', subscription)
    } else {
      success = await this.refreshContactToken(meClientSvc)
      if (success) {
        logging.Logger.debug('Successful refreshed the contact token')
        await this.registerNewSubscription(subscription, true)
      } else {
        logging.Logger.error('Unable to register expired subscription', subscription)
      }
    }
  }

  private async getApplicationServerKey (): Promise<string | undefined> {
    try {
      const result = await this.webPushDb.getApplicationServerPublicKey()
      if (!result) {
        logging.Logger.error('application server key not set')
      }
      return result
    } catch (err) {
      logging.Logger.error('application server error', err)
      return undefined
    }
  }

  private async showNotification (
    message: string,
    payload: any,
    notificationTitle: string,
    notificationIcon: string | undefined,
    notificationImage: string | undefined,
    notificationActions: NotificationAction[] | undefined
  ) {
    const notificationOptions = {
      body: message,
      data: payload,
      icon: notificationIcon,
      image: notificationImage,
      actions: notificationActions,
      vibrate: [400, 100, 400]
    }
    return self.registration.showNotification(notificationTitle, notificationOptions)
  }

  /*
   * Get an option for notification.
   * If the given option is in the customData (sent in the push) this value will be used.
   * Otherwise it tries to read a default value from browser's indexDb (saved on serviceworker registration).
   * If value is not present in indexDb it use the defaultValue parameter.
   */
  private async getNotificationOption<T = string> (
    notificationData: NotificationData<T>,
    notificationDataPropertyName: string,
    dbFallbackFn: DbFallbackFn<T>
  ): Promise<T | undefined> {
    // eslint-disable-next-line
    if (notificationData && notificationData[notificationDataPropertyName]) {
      return Promise.resolve(notificationData[notificationDataPropertyName])
    }
    return dbFallbackFn()
  }

  private async handleInstall (): Promise<void> {
    try {
      await this.setupLogging()
      logging.Logger.debug('Install handler')
      logging.Logger.debug('Storing service worker version', __VERSION__)
      await this.webPushDb.setServiceWorkerVersion(__VERSION__)
      logging.Logger.debug('Skipping waiting')
      await self.skipWaiting()
      logging.Logger.debug('Install done')
    } catch (err) {
      // this log shall be written if we could not access the webPushDb at all
      logging.Logger.error(err, 'Install error!')
    }
  }

  private async setupLogging (): Promise<void> {
    const loggingEnabled = await this.webPushDb.getLoggingEnabled()
    logging.enableLogger(loggingEnabled, logging.SwContext)
  }

  /**
   * Calls Emarsys open API endpoint to register the user has opened the notification.
   */
  private async reportOpen (notificationData: any): Promise<void> {
    const des = await this.getDeviceEventService()
    if (!des) {
      logging.Logger.error('Cannot report open! DES not initialized!', notificationData)
      return
    }
    try {
      logging.Logger.debug('Reporting open to DES', notificationData)
      const sid: string | undefined = notificationData?.messageData?.sid
      const treatments: WebPushTreatments | undefined = notificationData?.messageData?.treatments
      let attributes: MEEventAttributes | undefined = sid ? { sid } : undefined
      attributes = attributes ? treatments ? { ...attributes, treatments: JSON.stringify(treatments) } : attributes : undefined
      const openData: MEEvent = {
        type: 'internal', name: 'webpush:click', timestamp: new Date().toISOString(), attributes
      }
      const eventsData: MEEventsRequestData = { dnd: true, events: [openData], clicks: [], viewedMessages: [] }
      const result = await des.postEvents(eventsData)
      if (!result.success && result.statusCode === 401) {
        await this.retrySendAfterContactTokenRefresh(des, eventsData)
      }
    } catch (err) {
      logging.Logger.error('Fatal error while reporting open!', err.message, err)
    }
  }

  private async retrySendAfterContactTokenRefresh (
    des: MEDeviceEventService,
    eventsData: MEEventsRequestData
  ): Promise<PostEventsResult> {
    const meClientSvc = await this.getMeClientService()
    if (!meClientSvc) {
      logging.Logger.error('Unable to get the ME client service!')
      return FailureResult
    }
    const success = await this.refreshContactToken(meClientSvc)
    if (!success) {
      return FailureResult
    }
    return des.postEvents(eventsData)
  }

  private async refreshContactToken (meClientSvc: MEClientService): Promise<boolean> {
    try {
      const success = await meClientSvc.generateAccessToken()
      if (!success) {
        logging.Logger.error('refresh of access token failed')
      }
      return success
    } catch (err) {
      logging.Logger.error('unable to refresh contact token', err)
      return false
    }
  }

  private async getDeviceEventService (): Promise<MEDeviceEventService | undefined> {
    try {
      if (!this.meDeviceEventService) {
        const baseUrl = await this.webPushDb.getMeDeviceEventServiceApiBaseUrl()
        this.meDeviceEventService = MEDeviceEventService.create(baseUrl, MEV3ApiRequest.create(), this.webPushDb)
      }
      return this.meDeviceEventService
    } catch (err) {
      logging.Logger.error('Error initializing device event service!', err.message, err)
    }
  }

  private async getMeClientService (): Promise<MEClientService | undefined> {
    try {
      if (!this.meClientService) {
        const baseUrl = await this.webPushDb.getMeClientServiceApiBaseUrl()
        this.meClientService = MEClientService.create(baseUrl, MEV3ApiRequest.create(), this.webPushDb)
      }
      return this.meClientService
    } catch (err) {
      logging.Logger.error('Error initializing client service!', err.message, err)
    }
  }

  private createActionsFromActionButtons (actionButtons: ActionButton[]): NotificationAction[] {
    return actionButtons.map(actionButton => ({
      action: actionButton.id,
      title: actionButton.title
    }))
  }

  static create (webPushDb: MEWebPushDb): EmarsysServiceWorker {
    return new EmarsysServiceWorker(webPushDb)
  }
}
